export const realtorData = {
  email: 'CAROLINA.RUIZ@UNNE.CL',
  phone:'+56949583470'
};

export const footerData = {
  email: 'CAROLINA.RUIZ@UNNE.CL',
  phone:'949583470',
  wsp:'949583470',
}
