const ZoneInMap = [
    {
        id:1,
        region:'Región Metropolitana',
        comuna:'Santiago',
        longitude:-70.65023802887474,
        latitude: -33.42312703367724,
    }
  ]


  export default ZoneInMap;